<!--
 * @Descripttion: 操作指南
 * @Author: zyr
 * @Date: 2021-05-29 14:20:34
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-13 23:39:00
-->
<template>
  <div :class="{'guide-wrapper': true, 'home-wrapper': isBottom}">
    <div :class="{'guide-content': true, 'home-content': isBottom}" v-html="content">
    </div>
    <!-- <div class="home-footer">
      <p class="text-align">XX提供技术支持</p>
      <a href="tel:0511-3455899" class="footer-phone text-align">0511-3455899</a>
    </div> -->
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { getGuide } = API
export default {
  name: 'Guide',
  data () {
    return {
      content: '',
      isBottom: false
    }
  },
  methods: {
    getGuide () {
      this.$axios.get(getGuide, {
        params: {}
      }).then(({ code, model }) => {
        if (code === 0) {
          this.content = model
          // this.$nextTick(() => {
          // const obj = document.querySelector('.guide-content').lastElementChild.getBoundingClientRect()
          // const height = document.getElementById('app').clientHeight
          // this.isBottom = obj.bottom < height
          // })
        }
      })
    }
  },
  mounted () {
    this.getGuide()
  }
}
</script>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
.guide-content{
  margin:  0 .px2rem(32px)[@rem];
}
.guide-header{
  border-radius: .px2rem(20px)[@rem];
  background: #fff;
  padding: .px2rem(52px)[@rem] .px2rem(32px)[@rem];
  margin-bottom: .px2rem(26px)[@rem];
}
.guide-header-tlt{
  font-size: .px2rem(46px)[@rem];
  color: #5460FE;
  font-style: italic;
  text-align: center;
  margin-bottom: .px2rem(32px)[@rem];
}
.header-img{
  display: block;
  margin: auto;
}
.guide-desc{
  border-radius: .px2rem(10px)[@rem];
  background: #fff;
  padding: .px2rem(64px)[@rem] .px2rem(55px)[@rem] .px2rem(44px)[@rem];
  margin-bottom: .px2rem(96px)[@rem];
}
.guide-item{
  margin-bottom: .px2rem(44px)[@rem];
  &:last-child{
    margin-bottom: 0;
  }
}
.item-tlt{
  color: #101112;
  font-size: .px2rem(28px)[@rem];
}
.color-fff{
  color: #fff;
}
</style>
